import React from "react";
import ServiceLocationPicker from "./components/ServiceLocationPicker";
import physioData from "../../data/physioData.json";
import iconSelfcare from "../../assets/img/heart-person.webp";
import iconHealthCare from "../../assets/img/health-care.webp";
import iconBetreuung from "../../assets/img/health-checklist.webp";

function Physiotherapie() {
  const physioServices = physioData.services;
  const physioLocationData = physioData.locationData;
  const icons = [iconHealthCare, iconBetreuung, iconSelfcare];

  const RenderServiceGridItem = (service, index) => {
    return (
      <div className="service-grid-card">
        <div className="service-grid-card-icon">
          <img src={icons[index]} alt="service-icon"></img>
        </div>
        <p className="service-grid-items-header">{service.title}</p>
        <ul className="service-grid-list-items">
          {service.items.map((item, i) => {
            return (
              <>
                <li className="service-grid-list-item">
                  <div>{item}</div>
                </li>
              </>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="container" data-bgcolor="gray">
        <div className="inner-white-container">
          <div className="padding-block-700">
            <div className="service-page-intro">
              <h1 className="service-page-intro-header">Physiotherapie</h1>
              <div className="service-page-intro-text">
                <p>
                  Wir bieten Ihnen eine individuelle, befund- und
                  zielorientierte Betreuung zur Verbesserung Ihres körperlichen
                  Wohlbefindens und Steigerung Ihrer Leistungsfähigkeit im
                  Alltag. Physiotherapie ist ein Heilmittel und wird bei
                  ärztlicher Verordnung von der Krankenkasse erstattet.
                </p>
                <p>Wir beraten Sie gern!</p>
              </div>
            </div>
            <div className="service-grid">
              <h2 className="service-grid-header">Wir bieten Ihnen</h2>
              <div className="service-grid-list">
                {RenderServiceGridItem(physioServices[0], 0)}
                {RenderServiceGridItem(physioServices[1], 1)}
                {RenderServiceGridItem(physioServices[2], 2)}
              </div>
            </div>
            <div className="service-locations">
              <h2 className="service-locations-header">Vor Ort für Sie da</h2>
              <ServiceLocationPicker locationData={physioLocationData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Physiotherapie;
