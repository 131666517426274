import React from "react";
import ServiceLocationPicker from "./components/ServiceLocationPicker";
import iconResearch from "../../assets/img/Untersuchung.webp";
import iconHealthCare from "../../assets/img/health-care.webp";
import iconBetreuung from "../../assets/img/health-checklist.webp";

function Medizin() {
  const medLocationData = [
    {
      locationId: 0,
      contact: {
        person: "Fachärztin für Arbeitsmedizin Julia Salow",
        tel: "035365-3828130",
        mail: "arbeitsmedizin@epikur-gesundheit.de",
        openingTimes: ["Mo – Do:  8:00 bis 15:30 Uhr"],
      },
    },
  ];

  return (
    <>
      <div className="container" data-bgcolor="gray">
        <div className="inner-white-container">
          <div className="padding-block-700">
            <div className="service-page-intro">
              <h1 className="service-page-intro-header">
                Betriebsmedizin/ Arbeitsmedizin
              </h1>
            </div>
            <div className="service-grid">
              <h2 className="service-grid-header">Unsere Leistungen</h2>
              <div className="service-grid-list">
                <div className="service-grid-card">
                  <div className="service-grid-card-icon">
                    <img src={iconBetreuung} alt="service-icon"></img>
                  </div>
                  <p className="service-grid-items-header">
                    Arbeitsmedizinische Betreuung
                  </p>
                  <ul className="service-grid-list-items">
                    <li className="service-grid-list-item">
                      <div>
                        von Betrieben entsprechend Arbeitssicherheitsgesetz und
                        Arbeitsschutzgesetz, DGUV Vorschrift 2,
                        Arbeitsmedizinische Vorsorgeverordnung
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="service-grid-card">
                  <div className="service-grid-card-icon">
                    <img src={iconHealthCare} alt="service-icon"></img>
                  </div>
                  <p className="service-grid-items-header">
                    Arbeitsmedizinische Vorsorge
                  </p>
                  <ul className="service-grid-list-items">
                    <li className="service-grid-list-item">
                      <div>(Untersuchungen) der Beschäftigten</div>
                    </li>
                  </ul>
                </div>

                <div className="service-grid-card">
                  <div className="service-grid-card-icon">
                    <img src={iconResearch} alt="service-icon"></img>
                  </div>
                  <p className="service-grid-items-header">Untersuchung</p>
                  <ul className="service-grid-list-items">
                    <li className="service-grid-list-item">
                      <div>
                        nach der Fahrerlaubnisverordnung für alle Klassen,
                        inclusive LKW, Taxi, Sportbootsführerschein, P-Schein
                        und Bus
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 
            <div className="service-locations">
              <h2 className="service-locations-header">Vor Ort für Sie da</h2>
              <ServiceLocationPicker locationData={medLocationData} />
            </div>
             */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Medizin;
