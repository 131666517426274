import React from "react";
import ServiceLocationPicker from "./components/ServiceLocationPicker";
import ergoData from "../../data/ergoData.json";
import icon1 from "../../assets/img/neurologie_icon.webp";
import icon2 from "../../assets/img/psychologie_icon.webp";
import icon3 from "../../assets/img/orthopaedie_icon.webp";

function Ergotherapie() {
  const ergoServices = ergoData.services;
  const ergoLocationData = ergoData.locationData;
  const ergoIcons = [icon1, icon3, icon2];

  const RenderServiceGridItem = (service, index) => {
    return (
      <div className="service-grid-card">
        <div className="service-grid-card-icon">
          <img src={ergoIcons[index]} alt="service-icon"></img>
        </div>
        <p className="service-grid-items-header">{service.title}</p>
        <ul className="service-grid-list-items">
          {service.items.map((item, i) => {
            return (
              <>
                <li className="service-grid-list-item">
                  <div>{item}</div>
                </li>
              </>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="container" data-bgcolor="gray">
        <div className="inner-white-container">
          <div className="padding-block-700">
            <div className="service-page-intro">
              <h1 className="service-page-intro-header">Ergotherapie</h1>
              <div className="service-page-intro-text">
                <p>
                  hat das Ziel, Menschen mit Einschränkungen im alltäglichen
                  Leben, die durch Verletzungen, Krankheiten und Behinderungen
                  hervorgerufen werden, die größtmögliche Lebensqualität und
                  Selbständigkeit zu ermöglichen.
                </p>
                <p>
                  Ergotherapie ist ein Heilmittel und wird bei ärztlicher
                  Verordnung von der Krankenkasse erstattet.
                </p>
                <p>Wir beraten Sie gern!</p>
              </div>
            </div>
            <div className="service-grid">
              <h2 className="service-grid-header">Wir behandeln bei</h2>
              <div className="service-grid-list">
                {RenderServiceGridItem(ergoServices[0], 0)}
                {RenderServiceGridItem(ergoServices[1], 1)}
                {RenderServiceGridItem(ergoServices[2], 2)}
              </div>
              <div className="service-grid-info">
                Auf Verordnung vom Arzt kann die Behandlung auch im Hausbesuch
                oder Heim erfolgen.
              </div>
            </div>
            <div className="service-locations">
              <h2 className="service-locations-header">Vor Ort für Sie da</h2>
              <ServiceLocationPicker locationData={ergoLocationData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ergotherapie;
