import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import JobForm from "../components/Forms/JobForm";

function Jobs() {
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const JobFormComponent = () => {
    if (showConfirmation) {
      return (
        <div className="form-submit-message">
          <p>Herzlichen Dank für ihre Express-Bewerbung.</p>
          <p>
            Hiermit bestätigen wir die Ankunft dieser über das
            Express-Bewerbungsformular. Wir werden Sie so schnell wie möglich
            über die von Ihnen angegebene E-Mail kontaktieren.
          </p>
          <HashLink to="/" className="btn">
            Zurück zur Startseite
          </HashLink>
        </div>
      );
    } else {
      return <JobForm AfterSubmitAction={setShowConfirmation} />;
    }
  };

  return (
    <section id="job-request">
      <div className="padding-block-700 padding-inline">
        <h1 className="fs-primary-heading fw-bold text-center">
          Express-Bewerbung
        </h1>
        <JobFormComponent />
      </div>
    </section>
  );
}

export default Jobs;
