import React from "react";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Locations from "./pages/Locations";
import ImageGallery from "./components/AdvancedImageGallery";

function Main() {
  return (
    <>
      <Home />
      <Services />
      <ImageGallery />
    </>
  );
}

export default Main;
