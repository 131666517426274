import React from "react";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import burgerMenu from "../assets/img/burgerMenu.webp";
import logo from "../assets/img/logo.webp";
import { useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showNavLogo, setShowNavLogo] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setShowNavLogo(false);
    } else {
      setShowNavLogo(true);
    }
  }, [location]);

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
  };

  const MobileMenu = () => {
    if (showMobileMenu) {
      return (
        <div className="nav-mobile-links">
          <HashLink className="nav-link" to="/#home" onClick={onNavLinkClicked}>
            Start
          </HashLink>
          <HashLink
            className="nav-link"
            to="/#about"
            onClick={onNavLinkClicked}
          >
            Über
          </HashLink>
          <HashLink
            className="nav-link"
            to="/#services"
            onClick={onNavLinkClicked}
          >
            Leistungen
          </HashLink>
          <HashLink
            className="nav-link"
            to="/#locations"
            onClick={onNavLinkClicked}
          >
            Standorte
          </HashLink>
          <HashLink
            className="nav-link"
            to="/kontakt"
            onClick={onNavLinkClicked}
          >
            Kontakt
          </HashLink>
          <HashLink className="nav-link" to="/jobs" onClick={onNavLinkClicked}>
            Jobs
          </HashLink>
        </div>
      );
    }
    return;
  };

  return (
    <header className="primary-header">
      <div className="nav">
        {showNavLogo ? (
          <HashLink to="/#top">
            <img className="nav-logo" src={logo} alt="logo" />
          </HashLink>
        ) : (
          <div></div>
        )}

        <div className="nav-links">
          <div className="nav-extended-links">
            <HashLink className="nav-extended-links-item" to="/kontakt">
              Kontakt
            </HashLink>
            <div className="nav-divider">|</div>
            <HashLink className="nav-extended-links-item" to="/jobs">
              Jobs
            </HashLink>
          </div>
          <div className="nav-mobile">
            <img src={burgerMenu} alt="navMenu" onClick={openMobileMenu} />
          </div>
        </div>
      </div>
      <MobileMenu />
    </header>
  );
}

export default Navbar;
