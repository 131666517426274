import React from "react";
import ServiceLocationPicker from "./components/ServiceLocationPicker";
import iconSelfcare from "../../assets/img/heart-person.webp";
import iconHealthCare from "../../assets/img/health-care.webp";
import iconNote from "../../assets/img/note-icon.webp";
import kochKursNote from "../../assets/img/psychologie_icon.webp";

function Ernaehrungsberatung() {
  const LocationData = [
    {
      locationId: 0,
      contact: {
        person: "Stefanie Helbig",
        titles: [
          "Dipl. Ernährungswissenschaftlerin",
          "Qualifizierte Diät- und Ernährungsberaterin VFED",
        ],
        tel: "035365-3828140",
        mob: "+49(0)151 26823609",
        mail: "ernaehrungsberatung@epikur-gesundheit.de",
        openingTimes: [],
      },
    },
    {
      locationId: 1,
      contact: {
        person: "Stefanie Helbig",
        titles: [
          "Dipl. Ernährungswissenschaftlerin",
          "Qualifizierte Diät- und Ernährungsberaterin VFED",
        ],
        tel: "035365-3828140",
        mob: "+49(0)151 26823609",
        mail: "ernaehrungsberatung@epikur-gesundheit.de",
        openingTimes: [],
      },
    },
    {
      locationId: 2,
      contact: {
        person: "Stefanie Helbig",
        titles: [
          "Dipl. Ernährungswissenschaftlerin",
          "Qualifizierte Diät- und Ernährungsberaterin VFED",
        ],
        tel: "035365-3828140",
        mob: "+49(0)151 26823609",
        mail: "ernaehrungsberatung@epikur-gesundheit.de",
        openingTimes: [],
      },
    },
    {
      locationId: 3,
      contact: {
        person: "Stefanie Helbig",
        titles: [
          "Dipl. Ernährungswissenschaftlerin",
          "Qualifizierte Diät- und Ernährungsberaterin VFED",
        ],
        tel: "035365-3828140",
        mob: "+49(0)151 26823609",
        mail: "ernaehrungsberatung@epikur-gesundheit.de",
        openingTimes: [],
      },
    },
  ];

  return (
    <>
      <div className="container" data-bgcolor="gray">
        <div className="inner-white-container">
          <div className="padding-block-700">
            <div className="service-page-intro">
              <h1 className="service-page-intro-header">Ernährungsberatung</h1>
              <div className="service-page-intro-text">
                <p>
                  Eine richtige Ernährung trägt entscheidend zu unserer
                  Gesundheit und Fitness bei. Neben Sport und Bewegung hilft
                  eine gesunde Ernährung, Krankheiten wie Adipositas, Gicht,
                  Diabetes mellitus und Herz-Kreislauf-Erkrankungen vorzubeugen.
                </p>
                <p>
                  Wollen Sie Ihre Essgewohnheiten umstellen und sich selbst
                  etwas Gutes tun, wissen aber nicht so genau, wie Sie es
                  umsetzen können? Dann haben wir das Richtige für Sie: Das auf
                  Sie abgestimmte Gesundheitstraining sowie die individuelle
                  Ernährungstherapie sind unsere Leistungen, mit denen Sie einer
                  Vielzahl von Erkrankungen vorbeugen und gleichzeitig
                  Beschwerden lindern können.
                </p>
              </div>
            </div>
            <div className="service-grid">
              <h2 className="service-grid-header">Unsere Programme</h2>
              <div className="service-grid-list" data-type="two-by-two">
                <div className="service-grid-card">
                  <div className="service-grid-card-icon">
                    <img src={iconSelfcare} alt="service-icon"></img>
                  </div>
                  <p className="service-grid-items-header">
                    Individuelle Ernährungstherapie nach § 43 SGB V
                  </p>
                  <ul className="service-grid-list-items">
                    <li className="service-grid-list-item">
                      <div>
                        Programmdauer individuell, je nach Zielstellung – zur
                        Gewichtsreduktion, bei Erkrankungen wie Adipositas,
                        Diabetes mellitus, Herz- Kreislauf- Erkrankungen, Gicht,
                        Rheuma, Magen-Darm-Erkrankungen, Krebs, Erkrankungen der
                        Haut, Allergien und Unverträglichkeiten
                      </div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>
                        Teilnehmer: 1 Person, bei Kindern/ Jugendlichen bis zum
                        18. LJ inkl. 1 Begleitperson
                      </div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>Gesundheits- und Fitnesscheck</div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>
                        5 theoretische Einzelsitzungen mit Empfehlungen zum
                        Ernährungsverhalten. Auswertung durch eine
                        Ernährungssoftware, theoretisches Einkaufstraining,
                        Übungen zur Achtsamkeit, individuelle Zielsetzungen,
                        Motivationsarbeit
                      </div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>Betreuung durch eine Ernährungsberaterin</div>
                    </li>
                  </ul>
                </div>

                <div className="service-grid-card">
                  <div className="service-grid-card-icon">
                    <img src={iconHealthCare} alt="service-icon"></img>
                  </div>
                  <p className="service-grid-items-header">
                    Präventionskurse nach § 20 SGB V
                  </p>
                  <ul className="service-grid-list-items">
                    <li className="service-grid-list-item">
                      <div>Programmdauer 10 Wochen</div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>Teilnehmer in einer Gruppe, maximal 10 Personen</div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>
                        Zertifiziert bei der Zentralen Prüfstelle für Prävention
                        (ZPP)
                      </div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>
                        Beinhaltet theoretische und praktische Trainings zur
                        Umsetzung im individuellen Alltagn
                      </div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>Betreuung durch eine Ernährungsberaterin</div>
                    </li>
                  </ul>
                </div>

                <div className="service-grid-card">
                  <div className="service-grid-card-icon">
                    <img src={iconNote} alt="service-icon"></img>
                  </div>
                  <p className="service-grid-items-header">
                    Privates Einkaufstraining
                  </p>
                  <ul className="service-grid-list-items">
                    <li className="service-grid-list-item">
                      <div>
                        Hilfestellung zur optimalen Auswahl gesunder
                        Lebensmittel
                      </div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>Was heißt Fett i. Tr.?</div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>Moderne Getränke _ Fitness, Wellness, Energy?</div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>
                        Fett- und Zuckerfalle Supermarkt – Nährwerte und deren
                        Kennzeichnung
                      </div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>
                        Kühlschrankcheck – Das gehört rein – das nicht ( bei
                        Ersttermin gern auch durch Fotos machbar)
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="service-grid-card">
                  <div className="service-grid-card-icon">
                    <img src={kochKursNote} alt="service-icon"></img>
                  </div>
                  <p className="service-grid-items-header">Vortragstätigkeit</p>
                  <ul className="service-grid-list-items">
                    <li className="service-grid-list-item">
                      <div>Seminare, Workshops, Vorträge, Projekte</div>
                    </li>
                    <li className="service-grid-list-item">
                      <div>
                        individuelle Angebote zu ernährungsrelevanten Themen in
                        Schulen, Kitas, Eltern-Kind Gruppen, Unternehmen, für
                        Selbsthilfegruppen oder Interessierte
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="service-grid-info">
                Die Beratung ist auch in anderen Praxen bzw. bei Hausbesuchen
                möglich.
              </div>
            </div>
            <div className="service-locations">
              <h2 className="service-locations-header">Vor Ort für Sie da</h2>
              <ServiceLocationPicker locationData={LocationData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ernaehrungsberatung;
