import React from "react";
import cardImg1 from "../assets/img/service_1.webp";
import cardImg2 from "../assets/img/service_2.webp";
import cardImg3 from "../assets/img/service_3.webp";
import cardImg4 from "../assets/img/service_4.webp";
import { HashLink } from "react-router-hash-link";

function Services() {
  return (
    <section id="services">
      <div className="container">
        <div className="inner-white-container">
          <div className="padding-block-700">
            <div className="padding-block-700">
              <h1 className="primary-heading" data-color="orange">
                Willkommen bei Epikur!
              </h1>
            </div>

            <div className="card-list">
              <div className="card" data-type="upperLeft">
                <div className="card-header">Ergotherapie</div>
                <img className="card-img" src={cardImg1} alt="card-img"></img>
                <HashLink to="/ergotherapie" className="btn card-btn">
                  WEITERE INFORMATIONEN
                </HashLink>
              </div>

              <div className="card" data-type="upperRight">
                <div className="card-header">Physiotherapie</div>
                <img className="card-img" src={cardImg2} alt="card-img"></img>
                <HashLink to="/physiotherapie" className="btn card-btn">
                  WEITERE INFORMATIONEN
                </HashLink>
              </div>

              <div className="card" data-type="lowerLeft">
                <div className="card-header">
                  Betriebsmedizin / Arbeitsmedizin
                </div>
                <img className="card-img" src={cardImg4} alt="card-img"></img>
                <HashLink
                  to="/betriebsmedizin-arbeitsmedizin"
                  className="btn card-btn"
                >
                  WEITERE INFORMATIONEN
                </HashLink>
              </div>

              <div className="card" data-type="lowerRight">
                <div className="card-header">Ernährungsberatung</div>
                <img className="card-img" src={cardImg3} alt="card-img"></img>
                <HashLink to="/ernährungsberatung" className="btn card-btn">
                  WEITERE INFORMATIONEN
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
