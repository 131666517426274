import React, { useState, useEffect } from "react";
import imgLocation1 from "../assets/img/finsterwalde_1.webp";
import imgLocation2 from "../assets/img/herzberg_1.webp";
import imgLocation3 from "../assets/img/elsterwerda_1.webp";
import imgLocation4 from "../assets/img/falkenberg_1.webp";
import placeholder from "../assets/img/placeholder.webp";
import controlLeft from "../assets/img/Chevron-left.webp";
import controlRight from "../assets/img/Chevron-right.webp";

function Locations() {
  const [activeSliderImg, setActiveSliderImg] = useState([]);
  const [activeSliderIndex, setActiveSliderIndex] = useState(1);
  const [activeSliderHeader, setActiveSliderHeader] = useState("");
  const maxSliderIndex = 4;

  useEffect(() => {
    RenderSliderImg(activeSliderIndex);
  }, [activeSliderIndex]);

  const RenderSliderImg = (activeIndex) => {
    switch (activeIndex) {
      case 1:
        setActiveSliderHeader("Falkenberg");
        return setActiveSliderImg(
          <img className="slider-img" src={imgLocation4} alt="Standort" />
        );

      case 2:
        setActiveSliderHeader("Herzberg");
        return setActiveSliderImg(
          <img className="slider-img" src={imgLocation2} alt="Standort" />
        );

      case 3:
        setActiveSliderHeader("Elsterwerda");
        return setActiveSliderImg(
          <img className="slider-img" src={imgLocation3} alt="Standort" />
        );

      case 4:
        setActiveSliderHeader("Finsterwalde");
        return setActiveSliderImg(
          <img className="slider-img" src={imgLocation1} alt="Standort" />
        );

      default:
        setActiveSliderHeader("Falkenberg");
        return setActiveSliderImg(
          <img className="slider-img" src={imgLocation4} alt="Standort" />
        );
    }
  };

  const onClickSliderPrev = () => {
    if (activeSliderIndex - 1 < 1) {
      setActiveSliderIndex(maxSliderIndex);
    } else {
      var prevSliderIndex = activeSliderIndex - 1;
      setActiveSliderIndex(prevSliderIndex);
    }
  };

  const onClickSliderNext = () => {
    if (activeSliderIndex + 1 > maxSliderIndex) {
      setActiveSliderIndex(1);
    } else {
      var nextSliderIndex = activeSliderIndex + 1;
      setActiveSliderIndex(nextSliderIndex);
    }
  };

  return (
    <section id="locations">
      <div className="padding-block-700">
        <div className="container">
          <h1 className="lo-heading">Unsere Standorte</h1>
        </div>

        <div className="slider-container">
          <div className="slider-vertical-wrapper">
            <div className="lo-vertical-line"></div>
            <div className="lo-vertical-text">{activeSliderHeader}</div>
          </div>
          <div className="slider-img-wrapper">
            {activeSliderImg}
            <div className="slider-control-wrapper">
              <img src={controlLeft} onClick={onClickSliderPrev} alt="Zurück" />
              <img src={controlRight} onClick={onClickSliderNext} alt="Vor" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Locations;
