import React, { useState } from "react";
import axios from "axios";
import { HashLink } from "react-router-hash-link";

function JobForm({ AfterSubmitAction }) {
  const [showErrorFile1Type, setShowErrorFile1Type] = useState(false);
  const [showErrorFile2Type, setShowErrorFile2Type] = useState(false);
  const [showErrorFile1Size, setShowErrorFile1Size] = useState(false);
  const [showErrorFile2Size, setShowErrorFile2Size] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    mail: "",
    contactType: "",
    jobType: "",
    extraMessage: "",
    file1: "",
    file2: "",
  });

  const sendJobRequest = async () => {
    const form = document.getElementById("job-form");
    const _formData = new FormData(form);
    const formValues = [..._formData.entries()];

    const file1Type = formValues[7][1].type;
    const file2Type = formValues[8][1].type;

    const file1Size = formValues[7][1].size;
    const file2Size = formValues[8][1].size;

    if (file1Size > 0 && file1Type === "application/pdf") {
      setShowErrorFile1Type(false);
      if (file1Size > 20000000) {
        setShowErrorFile1Size(true);
        return;
      } else {
        setShowErrorFile1Size(false);
      }
    } else {
      setShowErrorFile1Type(true);
      return;
    }

    if (file2Size > 0) {
      if (file2Type === "application/pdf") {
        setShowErrorFile2Type(false);
        if (file2Size > 20000000) {
          setShowErrorFile2Size(true);
          return;
        } else {
          setShowErrorFile2Size(false);
        }
      } else {
        setShowErrorFile2Type(true);
        return;
      }
    }

    axios.post("server/sendJobMail.php", _formData).then(function (response) {
      AfterSubmitAction(true);
    });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    sendJobRequest();
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form id="job-form" data-type="job-request" onSubmit={onSubmitForm}>
      <div className="form-control">
        <label htmlFor="firstName" className="form-label">
          * Vorname
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="text"
          name="firstName"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="lastName" className="form-label">
          * Name
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="text"
          name="lastName"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="phone" className="form-label">
          * Telefon
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="tel"
          name="phone"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="mail" className="form-label">
          * E-Mail
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="mail"
          name="mail"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="contactType" className="form-label">
          So möchte ich kontaktiert werden
        </label>
        <select
          className="form-input"
          onChange={handleChange}
          name="contactType"
        >
          <option value="">Bitte auswählen</option>
          <option value="Telefon">Telefonisch</option>
          <option value="Mail">via E-Mail</option>
        </select>
      </div>

      <div className="form-control">
        <label htmlFor="jobType" className="form-label">
          * In diesem Bereich möchte ich gern arbeiten
        </label>
        <select
          className="form-input"
          onChange={handleChange}
          name="jobType"
          required
        >
          <option value="">Bitte auswählen</option>
          <option value="Physiotherapeut*In [Herzberg/Falkenberg]">
            Physiotherapeut*In [Herzberg/Falkenberg]
          </option>
          <option value="Masseur*In [Herzberg/Falkenberg]">
            Masseur*In [Herzberg/Falkenberg]
          </option>
          <option value="med. Bademeister*In [Herzberg/Falkenberg]">
            med. Bademeister*In [Herzberg/Falkenberg]
          </option>
          <option value="Logopäde*In [Herzberg/Falkenberg]">
            Logopäde*In [Herzberg/Falkenberg]
          </option>
          <option value="Logopäde*In [Herzberg/Falkenberg]">
            Reinigungsfachkraft [Herzberg/Falkenberg]
          </option>
        </select>
      </div>

      <div className="form-control">
        <label htmlFor="extraMessage" className="form-label">
          Das möchte ich zusätzlich mitteilen
        </label>
        <textarea
          className="form-input"
          onChange={handleChange}
          name="extraMessage"
        />
      </div>
      <div className="form-control">
        <label htmlFor="file1" className="form-label">
          * Anhang 1 als PDF (Zeugnisse, Lebenslauf, u.ä.) [max. 20MB]
        </label>
        <input type="file" id="file1" name="file1" onChange={handleChange} />
        {showErrorFile1Type ? (
          <p className="form-control-error-msg">Falscher Dateityp ausgewählt</p>
        ) : (
          <></>
        )}
        {showErrorFile1Size ? (
          <p className="form-control-error-msg">
            Maximale Dateigröße von 20MB überschritten
          </p>
        ) : (
          <></>
        )}
      </div>

      <div className="form-control">
        <label htmlFor="file2" className="form-label">
          Anhang 2 als PDF (Zeugnisse, Lebenslauf, u.ä.) [max. 20MB]
        </label>
        <input type="file" id="file2" name="file2" onChange={handleChange} />
        {showErrorFile2Type ? (
          <p className="form-control-error-msg">Falscher Dateityp ausgewählt</p>
        ) : (
          <></>
        )}
        {showErrorFile2Size ? (
          <p className="form-control-error-msg">
            Maximale Dateigröße von 20MB überschritten
          </p>
        ) : (
          <></>
        )}
      </div>

      <div className="form-control">
        <span className="form-control-info">
          <input
            type="checkbox"
            placeholder="Datenschutz"
            required
            name="datenschutz"
          />
          <span>
            Ich willige in die in der{" "}
            <HashLink to="/datenschutz">Datenschutzerklärung</HashLink>{" "}
            beschriebene Verarbeitung meiner Daten ein:{" "}
            <span className="form-control-info-hl">*</span>
          </span>
        </span>
      </div>

      <button type="submit" className="btn" data-type="inverted">
        Absenden
      </button>
    </form>
  );
}

export default JobForm;
