import React from "react";
import hrImage from "../assets/img/Epikur_Hero.webp";
import mobileHrImage from "../assets/img/Epikur_Hero_mobile.webp";
import logo from "../assets/img/hr_logo.webp";

function Home() {
  return (
    <section id="home">
      <div className="padding-block-500 hr-wrapper">
        <div className="hr-header-wrapper">
          <img className="hr-header-img" alt="hr-logo" src={logo}></img>
          <h3 className="hr-tertiary-heading">
            Die Anlaufstelle für ihr Wohlbefinden
          </h3>
        </div>
        <img
          className="hr-mobile-img"
          src={mobileHrImage}
          alt="heroMobileImg"
        ></img>
        <img className="hr-img" src={hrImage} alt="heroImg"></img>
      </div>
    </section>
  );
}

export default Home;
