import React, { useEffect, useState, useCallback, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import chevronLeft from "../assets/img/Chevron-left.webp";
import chevronRight from "../assets/img/Chevron-right.webp";

function AdvancedImageGallery() {
  const locationList = [
    {
      location: "Falkenberg",
      imgList: [
        "falkenberg_1.webp",
        "falkenberg_2.webp",
        "falkenberg_3.webp",
        "falkenberg_4.webp",
        "falkenberg_5.webp",
        "falkenberg_6.webp",
        "falkenberg_7.webp",
        "falkenberg_8.webp",
      ],
    },
    {
      location: "Herzberg",
      imgList: [
        "herzberg_1.webp",
        "herzberg_2.webp",
        "herzberg_3.webp",
        "herzberg_4.webp",
        "herzberg_5.webp",
      ],
    },
    {
      location: "Elsterwerda",
      imgList: [
        "elsterwerda_1.webp",
        "elsterwerda_2.webp",
        "elsterwerda_3.webp",
        "elsterwerda_4.webp",
        "elsterwerda_5.webp",
      ],
    },
    {
      location: "Finsterwalde",
      imgList: [
        "finsterwalde_1.webp",
        "finsterwalde_2.webp",
        "finsterwalde_3.webp",
        "finsterwalde_4.webp",
        "finsterwalde_5.webp",
        "finsterwalde_6.webp",
      ],
    },
  ];

  const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
  const [currentImgSliderIndex, setCurrentImgSliderIndex] = useState(0);
  const [activeAutoplay, setActiveAutoplay] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [activeImgList, setActiveImgList] = useState(locationList[0].imgList);
  const autoplayRef = useRef();
  const autoplayRefLocationSlider = useRef();

  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  var settingsImgSlider = {
    maxItems: activeImgList.length,
    maxLocations: 4,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const goToLocation = useCallback(
    (index, backTrack) => {
      setCurrentLocationIndex(index);
      setIsAnimating(true);
      if (backTrack) {
        var imgIndex = locationList[index].imgList.length - 1;
        setCurrentImgSliderIndex(imgIndex);
        goToImgSlider(imgIndex);
      } else {
        var imgIndex = 0;
        setCurrentImgSliderIndex(imgIndex);
        goToImgSlider(imgIndex);
      }
      setActiveImgList(locationList[index].imgList);

      setTimeout(() => {
        setIsAnimating(false);
      }, settingsImgSlider.speed);
    },
    [isAnimating, currentLocationIndex]
  );

  const goToImgSlider = useCallback(
    (index) => {
      if (!isAnimating) {
        setCurrentImgSliderIndex(index);
        setIsAnimating(true);

        setTimeout(() => {
          setIsAnimating(false);
        }, settingsImgSlider.speed);
      }
    },
    [isAnimating, currentImgSliderIndex]
  );

  /* Go Next */
  const goNextImgSlider = () => {
    if (currentImgSliderIndex >= settingsImgSlider.maxItems - 1) {
      goNextLocationSlider();
    } else {
      goToImgSlider(currentImgSliderIndex + 1);
    }
  };

  /* Go Prev */
  const goPrevImgSlider = () => {
    if (currentImgSliderIndex <= 0) {
      goPrevLocationSlider();
    } else {
      goToImgSlider(currentImgSliderIndex - 1);
    }
  };

  const goNextLocationSlider = () => {
    if (currentLocationIndex >= settingsImgSlider.maxLocations - 1) {
      goToLocation(0, false);
    } else {
      goToLocation(currentLocationIndex + 1, false);
    }
  };

  const goPrevLocationSlider = () => {
    if (currentLocationIndex <= 0) {
      goToLocation(settingsImgSlider.maxLocations - 1, true);
    } else {
      goToLocation(currentLocationIndex - 1, true);
    }
  };

  /* Play Timer */
  const playTimer = () => {
    setActiveAutoplay(true);
  };

  /* Pause Timer */
  const pauseTimer = () => {
    setActiveAutoplay(false);
  };

  const animateLocationSlider = (index) => {
    const locationPickerList =
      document.getElementsByClassName("lo-location-list")[0];
    const locationItem = locationPickerList.querySelector(
      "[data-value='" + index + "']"
    );
    if (locationItem) {
      var prevItem = null;
      if (currentLocationIndex <= 0) {
        prevItem =
          locationItem.parentElement.children[
            settingsImgSlider.maxLocations - 1
          ];
      } else {
        prevItem =
          locationItem.parentElement.children[currentLocationIndex - 1];
      }

      if (isMobile) {
        for (let i = 0; i < locationItem.parentElement.children.length; i++) {
          locationItem.parentElement.children[i].removeAttribute("data-type");
        }

        locationItem.setAttribute("data-type", "active");
      } else {
        for (let i = 0; i < locationItem.parentElement.children.length; i++) {
          locationItem.parentElement.children[i].children[1].removeAttribute(
            "data-type"
          );
        }

        locationItem.children[1].setAttribute("data-type", "active");
      }
    }
  };

  const goToLocationInSlider = (index) => {
    const locationPickerListItems =
      document.getElementsByClassName("lo-location-list")[0].children;

    for (let i = 0; i < locationPickerListItems.length; i++) {
      var locationIndex = locationPickerListItems[i].getAttribute("data-value");

      if (isMobile) {
        if ("" + index + "" !== "" + locationIndex + "") {
          locationPickerListItems[i].removeAttribute("data-type");
        } else {
          locationPickerListItems[i].setAttribute("data-type", "active");
        }
      } else {
        if ("" + index + "" !== "" + locationIndex + "") {
          locationPickerListItems[i].children[1].removeAttribute("data-type");
        } else {
          locationPickerListItems[i].children[1].setAttribute(
            "data-type",
            "active"
          );
        }
      }
    }

    goToLocation(index, false);
  };

  useEffect(() => {
    if (settingsImgSlider.autoplay && activeAutoplay) {
      clearTimeout(autoplayRef.current);

      autoplayRef.current = setTimeout(() => {
        goNextImgSlider();
      }, settingsImgSlider.autoplaySpeed);
    }
  }, [currentImgSliderIndex, activeAutoplay, isAnimating]);

  useEffect(() => {
    animateLocationSlider(currentLocationIndex);
  }, [currentLocationIndex]);

  const slide = (index) => {
    var sliderImg = require("../assets/img/" + activeImgList[index]);

    return <img className="slider-img" src={sliderImg} alt="Standort" />;
  };

  return (
    <section id="locations">
      <div className="padding-block-700">
        <div className="container">
          <h1 className="lo-heading">Unsere Standorte</h1>
        </div>
        <div className="lo-location-picker">
          <div className="lo-location-list">
            <div
              className="lo-location-list-item"
              data-value="0"
              data-type={isMobile ? "active" : ""}
              onClick={() => {
                goToLocationInSlider(0);
              }}
            >
              <div className="lo-location-list-item-text">Falkenberg</div>
              {isMobile ? (
                <></>
              ) : (
                <div
                  className="lo-location-list-item-underline"
                  data-type="active"
                ></div>
              )}
            </div>
            <div
              className="lo-location-list-item"
              data-value="1"
              data-side="right"
              onClick={() => {
                goToLocationInSlider(1);
              }}
            >
              <div className="lo-location-list-item-text">Herzberg</div>
              {isMobile ? (
                <></>
              ) : (
                <div className="lo-location-list-item-underline"></div>
              )}
            </div>
            <div
              className="lo-location-list-item"
              data-value="2"
              data-pos="lower"
              onClick={() => {
                goToLocationInSlider(2);
              }}
            >
              <div className="lo-location-list-item-text">Elsterwerda</div>
              {isMobile ? (
                <></>
              ) : (
                <div className="lo-location-list-item-underline"></div>
              )}
            </div>
            <div
              className="lo-location-list-item"
              data-value="3"
              data-pos="lower"
              data-side="right"
              onClick={() => {
                goToLocationInSlider(3);
              }}
            >
              <div className="lo-location-list-item-text">Finsterwalde</div>
              {isMobile ? (
                <></>
              ) : (
                <div className="lo-location-list-item-underline"></div>
              )}
            </div>
          </div>
          {isMobile ? <></> : <div className="lo-horizontal-line"></div>}
        </div>
        <div className="card-slider">
          <div className="slider-img-wrapper">
            {slide(currentImgSliderIndex)}
            <div className="slider-control-wrapper">
              <img src={chevronLeft} onClick={goPrevImgSlider} alt="Zurück" />
              <img src={chevronRight} onClick={goNextImgSlider} alt="Vor" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdvancedImageGallery;
